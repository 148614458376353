<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar">
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex block">
              <div>
                <h1 class="title">USDT Payments</h1>
                <h2 class="subtitle">
                  Check the payments from users
                </h2>
              </div>
              <input
                  v-model="txSearch"
                  class="input"
                  type="search"
                  placeholder="Search payments"
                  style="width: 25%"
              />
            </div>
            <div class="pagination">
              <div class="block">
                <div class="field is-grouped is-flex is-align-items-center">
                  <div class="is-flex is-align-items-center mr-2">
                    <span>Items per page:  </span>
                  </div>
                  <div class="field is-horizontal mb-0">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input
                                v-model.number="tableItemsPerPage"
                                class="input"
                                placeholder="days"
                                type="number"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="is-flex is-align-items-center mx-2">
                    <span>Current page:  </span>
                  </div>
                  <div class="field is-horizontal mb-0">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input
                                v-model.number="tableCurrentPage"
                                class="input"
                                placeholder="days"
                                type="number"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="control ml-2">
                    <button @click="changeTablePage(tableCurrentPage - 1)" :disabled="tableCurrentPage === 1" class="button is-primary mr-2">Prev</button>
                    <button @click="changeTablePage(tableCurrentPage + 1)" :disabled="tableCurrentPage >= paginatedTransactionsMaxPage" class="button is-primary">Next</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card block">
              <div class="card-content">
                <table class="table is-fullwidth is-hoverable is-narrow">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th @click="changeSortBy('date')">Date</th>
                    <th @click="changeSortBy('email')">User Email</th>
                    <th @click="changeSortBy('discord')">User Discord</th>
                    <th @click="changeSortBy('paid')">Paid</th>
                    <th @click="changeSortBy('coin')">Coin</th>
                    <th @click="changeSortBy('confirmations')">Confirmations</th>
                    <th @click="changeSortBy('hash_in')">Hash In</th>
                    <th @click="changeSortBy('hash_out')">Hash Out</th>
                  </tr>
                  </thead>
                  <tfoot>
                  <tr>
                    <th>#</th>
                    <th @click="changeSortBy('date')">Date</th>
                    <th @click="changeSortBy('email')">User Email</th>
                    <th @click="changeSortBy('discord')">User Discord</th>
                    <th @click="changeSortBy('paid')">Paid</th>
                    <th @click="changeSortBy('coin')">Coin</th>
                    <th @click="changeSortBy('confirmations')">Confirmations</th>
                    <th @click="changeSortBy('hash_in')">Hash In</th>
                    <th @click="changeSortBy('hash_out')">Hash Out</th>
                  </tr>
                  </tfoot>
                  <tbody>
                  <tr v-for="(tx, i) in paginatedTransactions" :key="tx.id">
                    <th>{{ i + 1 }}</th>
                    <td>{{ tx.date.toDate().toLocaleString() }}</td>
                    <td>{{ tx.user_email }}</td>
                    <td>{{ tx.user_discord }}</td>
                    <td>${{ tx.value_coin | fiat }}</td>
                    <td>{{ tx.coin }}</td>
                    <td>{{ tx.confirmations }}</td>
                    <td>
                      <a
                          :href="txLink(tx) + tx.txid_in"
                          target="_blank"
                      >Explore</a
                      >
                    </td>
                    <td>
                      <a
                          :href="txLink(tx) + tx.txid_out"
                          target="_blank"
                      >Explore</a
                      >
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { db } from "@/firebase";
import * as fb from "@/firebase";

export default {
  name: "TransactionsPaymentsView",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      txSearch: null,
      transactions: [],
      tableCurrentPage: 1,
      tableItemsPerPage: 50,
      sortTableBy: "date",
      sortTableDirection: "desc",
    };
  },
  firestore: {
    transactions: db.collection("blockbee_transactions"),
  },
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
    fiat: function(value) {
      if (!value) return 0;
      return parseFloat(value).toFixed(2);
    },
  },
  computed: {
    orderedTransactions() {
      if (this.sortTableBy === 'date') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return b.date.toDate() - a.date.toDate();
          } else {
            return a.date.toDate() - b.date.toDate();
          }
        });
      } else if (this.sortTableBy === 'email') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return (b.user_email || "").localeCompare(a.user_email || "");
          } else {
            return (a.user_email || "").localeCompare(b.user_email || "");
          }
        });
      } else if (this.sortTableBy === 'discord') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return (b.user_discord || "").localeCompare(a.user_discord || "");
          } else {
            return (a.user_discord || "").localeCompare(b.user_discord || "");
          }
        });
      } else if (this.sortTableBy === 'paid') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return parseFloat(b.value_coin) - parseFloat(a.value_coin);
          } else {
            return parseFloat(a.value_coin) - parseFloat(b.value_coin);
          }
        });
      } else if (this.sortTableBy === 'coin') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return b.coin - a.coin;
          } else {
            return a.coin - b.coin;
          }
        });
      } else if (this.sortTableBy === 'confirmations') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return b.confirmations - a.confirmations;
          } else {
            return a.confirmations - b.confirmations;
          }
        });
      } else if (this.sortTableBy === 'hash_in') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return (b.txid_in || "").localeCompare(a.txid_in || "");
          } else {
            return (a.txid_in || "").localeCompare(b.txid_in || "");
          }
        });
      } else if (this.sortTableBy === 'hash_out') {
        return this.transactions.sort((a, b) => {
          if (this.sortTableDirection === 'desc') {
            return (b.txid_out || "").localeCompare(a.txid_out || "");
          } else {
            return (a.txid_out || "").localeCompare(b.txid_out || "");
          }
        });
      }
      // return this.transactions.sort((a, b) => a.date - b.date);
    },
    filteredTransactions() {
      if (!this.txSearch) {
        return this.orderedTransactions;
      } else {
        return this.orderedTransactions.filter((tx) => {
          let search_lowercase = this.txSearch.toLowerCase();
          if (
              tx.email &&
              tx.email.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
              tx.user_discord &&
              tx.user_discord.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
              tx.user_discord_id &&
              tx.user_discord_id.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
              tx.hash &&
              tx.hash.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else
            return !!(
                tx.address &&
                tx.address.toLowerCase().includes(search_lowercase)
            );
        });
      }
    },
    paginatedTransactionsMaxPage() {
      return Math.ceil(this.filteredTransactions.length / this.tableItemsPerPage);
    },
    paginatedTransactions() {
      // return new copy of array paginated
      return this.filteredTransactions.slice(
          (this.tableCurrentPage - 1) * this.tableItemsPerPage,
          this.tableCurrentPage * this.tableItemsPerPage
      );
    },
  },
  methods: {
    changeSortBy(column) {
      if (this.sortTableBy === column) {
        this.sortTableDirection = this.sortTableDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortTableBy = column;
        this.sortTableDirection = "desc";
      }
    },
    membershipText(user) {
      if (user.subscription_end_date?.toDate() > new Date()) {
        return user.subscription_end_date?.toDate().toLocaleString();
      } else if (user.subscription_end_date?.toDate() < new Date()) {
        return "expired";
      } else {
        return "inactive";
      }
    },
    discordText(user) {
      if (user.discord_id) {
        return `${user.discord_username}#${user.discord_discriminator}`;
      } else {
        return "N/A";
      }
    },
    txLink(tx) {
      if (tx.coin.includes("erc20")) {
        return "https://etherscan.io/tx/"
      } else if (tx.coin.includes("trc20")) {
        return "https://tronscan.io/#/transaction/"
      } else if (tx.coin.includes("bep20")) {
        return "https://bsctrace.com/tx/"
      } else if (tx.coin.includes("")) {
        return "https://polygonscan.com/tx/"
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    deleteUser(user) {
      this.$firestoreRefs.users.doc(user.id).delete();
    },
    changeTablePage(page) {
      this.tableCurrentPage = page;
    },
  },
};
</script>
